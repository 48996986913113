<template>
  <div class="addActivity">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="footerShow"
      @update="update"
    >
      <!-- <el-form-item
        label="选择园区"
        :rules="[{ required: false, message: '请选择园区', trigger: 'blur' }]"
        prop="authSpaceIds"
      >
        <v-cascader
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
        />
      </el-form-item> -->
      <!-- 用户类别 -->
      <el-form-item
        label="用户类别"
        prop="authSpaceTypes"
        :rules="[
          { required: true, validator: spaceTypeValid, trigger: 'blur' },
        ]"
      >
        <div class="check-panel">
          <v-checkbox
            :disabled="isEditMode"
            v-model="item.checked"
            v-for="(item, index) in userTypeOps"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
            @change="checkChange"
          />
        </div>
      </el-form-item>
      <!-- 发布范围 -->
      <el-form-item
        v-show="form.authSpaceTypes !== undefined"
        label="发布范围"
        prop="authSpaceIds"
        :rules="[
          { required: false, message: '请选择发布范围', trigger: 'blur' },
        ]"
      >
        <v-cascader
          placeholder="默认表示全部"
          :width="220"
          :disabled="isEditMode"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
        />
      </el-form-item>
      <!-- 活动名称 -->
      <el-form-item
        label="活动名称"
        :rules="[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ]"
        prop="activityName"
      >
        <v-input
          placeholder="请输入活动名称"
          v-model="form.activityName"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <!-- 活动类型 -->
      <el-form-item
        label="活动类型"
        :rules="[
          { required: true, message: '请选择活动类型', trigger: 'change' },
        ]"
        prop="activityCategory"
      >
        <v-select
          :disabled="isEditMode"
          @change="activityCategoryChange"
          :options="activityTypeList"
          v-model="form.activityCategory"
        />
      </el-form-item>
      <!-- 是否隐藏 -->
      <el-form-item
        label="是否隐藏"
        :rules="[
          { required: true, message: '请选择是否隐藏', trigger: 'change' },
        ]"
        prop="hiddenFlag"
      >
        <v-select :options="isHidden" v-model="form.hiddenFlag" />
      </el-form-item>
      <!-- 是否置顶 -->
      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'change' },
        ]"
        prop="isRecommend"
      >
        <v-select :options="isTopOps" v-model="form.isRecommend" />
      </el-form-item>
      <!-- 置顶排序 -->
      <el-form-item
        v-if="form.isRecommend === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.sortIndex"
          min="1"
          type="number"
        ></v-input>
      </el-form-item>
      <!-- 承诺书内容 -->
      <!-- <el-form-item
        v-show="form.activityCategory == 20"
        label="承诺书内容"
        :rules="[
          {
            required: form.activityCategory == 20,
            message: '请签署承诺书',
            trigger: 'blur',
          },
        ]"
        prop="commitmentLetter"
      >
        <v-ueditor
          :maxLength="20000"
          v-model="form.commitmentLetter"
          :isShowImg="false"
        />
      </el-form-item> -->
      <!-- 活动场景类型 -->
      <el-form-item
        label="活动场景类型"
        :rules="[
          { required: true, message: '请选择活动场景类型', trigger: 'blur' },
        ]"
        prop="activitySceneType"
      >
        <v-radio
          :radioObj="activitySceneTypeRadio"
          v-model="form.activitySceneType"
          @change="changeActivitySceneType()"
        ></v-radio>
      </el-form-item>
      <!-- 场景ID -->
      <el-form-item
        label="场景ID"
        :rules="[{ required: true, message: '请选择场景ID', trigger: 'blur' }]"
        prop="sceneIds"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in sceneIdsList"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
          />
        </div>
      </el-form-item>
      <!-- 上报用场景类型 -->
      <el-form-item
        label="上报用场景类型"
        :rules="[
          {
            required: false,
            message: '请选择上报用场景类型',
            trigger: 'blur',
          },
        ]"
        prop="statisticsScenes"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in reportScenesList"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
          />
        </div>
      </el-form-item>
      <!-- 活动报名方式 -->
      <el-form-item
        label="活动报名方式"
        :rules="[
          { required: true, message: '请选择活动报名方式', trigger: 'change' },
        ]"
        prop="activityPattern"
        v-if="
          form.activityCategory == 19 ||
          form.activityCategory == 1 ||
          form.activityCategory == 110
        "
      >
        <v-select
          :disabled="isEditMode"
          :options="registrationMethodObj"
          v-model="form.activityPattern"
          @change="activityPatternChange"
        />
      </el-form-item>
      <!-- 报名时间 -->
      <el-form-item
        label="报名时间"
        v-if="
          form.activityCategory == 20 ||
          (form.activityPattern && form.activityPattern != 1)
        "
      >
        <v-date-picker
          v-model="applyDate23"
          type="datetimerange"
        ></v-date-picker>
      </el-form-item>
      <!-- 活动时间 -->
      <el-form-item
        label="活动时间"
        v-if="
          form.activityCategory == 20 ||
          (form.activityPattern && form.activityPattern != 1)
        "
      >
        <v-date-picker
          :disabled="isEditMode"
          v-model="activityDate23"
          type="datetimerange"
        ></v-date-picker>
      </el-form-item>
      <!-- 是否允许报名多场 -->
      <el-form-item
        label="是否允许报名多场"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="allowApplyMulti"
        v-if="
          form.activityPattern == 1 ||
          form.activityCategory == 20 ||
          form.activityCategory == 110
        "
      >
        <v-radio
          :disabled="isEditMode"
          :radioObj="radioApplyObj"
          v-model="form.allowApplyMulti"
          @change="changeApply"
        ></v-radio>
      </el-form-item>
      <!-- 活动场次-短期模式 -->
      <div
        v-if="form.activityPattern == 1 && form.activityCategory != 20"
        class="shortTermMode"
      >
        <el-form-item
          label="活动场次"
          :rules="[
            { required: true, message: '请输入活动场次', trigger: 'blur' },
          ]"
          prop="scheduleList"
        >
          <div class="times">
            <div
              class="areaTimes"
              v-for="(item, index) in form.scheduleList"
              :key="index"
            >
              <div class="areaTimes-l">
                <div class="item">
                  <div class="item-l">活动人数</div>
                  <div class="item-r">
                    <el-form-item
                      :rules="[
                        {
                          required: true,
                          message: '请输入活动人数',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`scheduleList.${index}.limitCount`"
                    >
                      <v-input
                        placeholder="请输入活动人数， 0-表示不限制"
                        v-model="item.limitCount"
                        :width="250"
                        min="0"
                        type="number"
                      ></v-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="item-l">是否限制单次报名参与人数</div>
                  <div class="item-r">
                    <v-radio
                      :radioObj="radioObj"
                      v-model="item.isLimit"
                      @change="change($event, item)"
                    ></v-radio>
                    <div class="inputPeople" v-if="item.isLimit == 1">
                      <v-input
                        placeholder="请输入限制人数"
                        v-model="item.perLimitCount"
                        :width="160"
                        min="0"
                        :max="item.limitCount"
                        type="number"
                      ></v-input>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-l">报名时间</div>
                  <div class="item-r">
                    <el-form-item
                      :rules="[
                        {
                          type: 'array',
                          required: true,
                          message: '请选择报名时间',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`scheduleList.${index}.applyDate`"
                    >
                      <v-date-picker
                        v-model="item.applyDate"
                        type="datetimerange"
                      ></v-date-picker>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="item-l">活动时间</div>
                  <div class="item-r">
                    <el-form-item
                      :rules="[
                        {
                          type: 'array',
                          required: true,
                          message: '请选择活动时间',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`scheduleList.${index}.activityDate`"
                    >
                      <v-date-picker
                        v-model="item.activityDate"
                        type="datetimerange"
                      ></v-date-picker>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="item-l">代币规则</div>
                  <div class="item-r">
                    <v-select
                      clearable
                      :options="activityPointSettingList"
                      v-model="item.pointsSettingId"
                    />
                  </div>
                </div>
              </div>
              <div class="areaTimes-r" v-if="!isEditMode">
                <div class="opera">
                  <v-button
                    text="删除"
                    v-if="index != 0"
                    @click="outRoom(item, index)"
                    type="warning"
                  ></v-button>
                  <v-button
                    v-if="index == form.scheduleList.length - 1"
                    text="新增"
                    @click="addRoom"
                  ></v-button>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <!-- 活动场次-周期模式 -->
      <div
        v-if="form.activityPattern == 2 && form.activityCategory != 20"
        class="cycleMode"
      >
        <el-form-item
          label="选择星期"
          :rules="[
            { required: true, validator: validatorWeek, trigger: 'blur' },
          ]"
          prop="weeks"
        >
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              :disabled="isEditMode"
              v-for="(item, index) in weekList"
              :key="index"
              :index="index"
              type="border"
              :label="item.label"
              @change="changeWeekList(item)"
            />
          </div>
        </el-form-item>
        <el-form-item label="选择场次">
          <v-button
            text="添加场次"
            :disabled="isEditMode"
            @click="addSession"
          ></v-button>
        </el-form-item>
        <el-form-item>
          <div
            v-if="form.scheduleList.length > 0"
            style="
              padding: 0 40px 40px 20px;
              height: 500px;
              overflow-y: auto;
              width: 100%;
            "
          >
            <v-table
              :tableData="form.scheduleList"
              :isOperateColumn="!isEditMode"
              ref="vTable"
              :headers="headers2"
            >
              <template #operateTeSlot="scope">
                <div class="opera-btn">
                  <v-button
                    text="编辑"
                    type="text"
                    @click="editSession(scope.row.row, scope.row.$index)"
                  />
                  <v-button
                    text="删除"
                    type="text"
                    @click="delSession(scope.row.row, scope.row.$index)"
                  />
                </div>
              </template>
            </v-table>
          </div>
        </el-form-item>
      </div>
      <!-- 活动场次-定期模式（时间银行） -->
      <div v-if="form.activityCategory == 20" class="recurrencePatternMode">
        <el-form-item label="选择场次">
          <v-button
            text="添加场次"
            :disabled="isEditMode"
            @click="addSession"
          ></v-button>
        </el-form-item>
        <el-form-item>
          <div
            v-if="form.scheduleList.length > 0"
            style="
              padding: 0 40px 40px 20px;
              height: 500px;
              overflow-y: auto;
              width: 100%;
            "
          >
            <v-table
              :tableData="form.scheduleList"
              ref="vTable"
              :isOperateColumn="!isEditMode"
              :headers="headers3"
            >
              <template #operateTeSlot="scope">
                <div class="opera-btn">
                  <v-button
                    text="编辑"
                    type="text"
                    @click="editSession(scope.row.row, scope.row.$index)"
                  />
                  <v-button
                    text="删除"
                    type="text"
                    @click="delSession(scope.row.row, scope.row.$index)"
                  />
                </div>
              </template>
            </v-table>
          </div>
        </el-form-item>
      </div>
      <!-- 活动地点 -->
      <el-form-item
        label="活动地点"
        :rules="[
          { required: false, message: '请输入活动地点', trigger: 'blur' },
        ]"
        prop="placeId"
      >
        <div style="display: flex">
          <v-select
            placeholder="请选择活动地点"
            :options="areaList"
            filterable
            clearable
            v-model="form.placeId"
            @change="selectPlace"
          />
          <div style="margin-left: 10px">
            <v-input
              v-if="form.placeId == ''"
              v-model="form.activityAddress"
              placeholder="请输入活动地点"
            />
          </div>
        </div>
      </el-form-item>
      <!-- 积分规则 -->
      <el-form-item
        label="积分规则"
        :rules="[
          { required: false, message: '请选择积分规则', trigger: 'blur' },
        ]"
        prop="pointsSettingId"
        v-if="form.activityCategory == 20 || form.activityPattern == 2"
      >
        <v-select
          clearable
          :options="activityPointSettingList"
          v-model="pointsSettingId"
        />
      </el-form-item>
      <!-- 活动图片 -->
      <el-form-item
        label="活动图片"
        :rules="[
          { required: true, message: '请上传活动图片', trigger: 'blur' },
        ]"
        prop="activityPicture"
      >
        <div id="activityImg">
          <v-upload
            :limit="1"
            :imgUrls.sync="form.activityPicture"
            tips="建议上传900px*383px图片"
          />
        </div>
      </el-form-item>
      <!-- 活动文本 -->
      <el-form-item
        label="活动文本"
        :rules="[
          { required: false, message: '请输入活动文本', trigger: 'blur' },
        ]"
        prop="content"
      >
        <v-ueditor
          :maxLength="20000"
          v-model="form.content"
          :isShowImg="false"
        />
      </el-form-item>
      <!-- 活动图片 -->
      <el-form-item
        label="活动图片"
        :rules="[
          {
            required: false,
            message: '请上传活动图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="imageUrls"
      >
        <v-upload :limit="9" :imgUrls.sync="form.imageUrls" />
      </el-form-item>
      <!-- 活动视频 -->
      <el-form-item
        label="活动视频"
        :rules="[{ required: false, message: '请上传视频', trigger: 'blur' }]"
        prop="videoUrl"
      >
        <v-videoUpload :limit="1" :videoUrls.sync="form.videoUrl" />
      </el-form-item>
    </form-panel>
    <!-- 定期模式添加场次弹框 -->
    <v-dialog
      :close-on-click-modal="false"
      title="添加场次"
      v-model="showDialog3"
      width="50%"
    >
      <el-form>
        <el-form-item label="开始时间">
          <v-time
            v-model="item3.startTime"
            valueFormat="HH:mm"
            format="HH:mm"
            :pickerOptions="null"
            :width="190"
            placeholder="请选择开始时间"
          ></v-time
        ></el-form-item>
        <el-form-item label="结束时间">
          <v-time
            v-model="item3.endTime"
            valueFormat="HH:mm"
            format="HH:mm"
            :pickerOptions="null"
            :width="190"
            placeholder="请选择结束时间"
          ></v-time>
        </el-form-item>
        <el-form-item label="需要人数">
          <v-input
            placeholder="请输入需要人数"
            v-model="item3.limitCount"
            min="0"
            type="number"
          ></v-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="showDialog3 = false"
          text="取消"
        ></v-button>
        <v-button size="medium" @click="submit()" text="确定"></v-button>
      </div>
    </v-dialog>
    <!-- 周期模式添加场次弹框 -->
    <v-dialog
      :close-on-click-modal="false"
      title="添加场次"
      v-model="showDialog2"
      width="50%"
    >
      <el-form>
        <el-form-item label="星期">
          <v-select :options="dialogWeekList" v-model="item2.week" />
        </el-form-item>
        <el-form-item label="开始时间">
          <v-time
            v-model="item2.startTime"
            valueFormat="HH:mm"
            format="HH:mm"
            :width="190"
            placeholder="请选择开始时间"
          ></v-time
        ></el-form-item>
        <el-form-item label="结束时间">
          <v-time
            v-model="item2.endTime"
            valueFormat="HH:mm"
            format="HH:mm"
            :width="190"
            placeholder="请选择结束时间"
          ></v-time>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="showDialog2 = false"
          text="取消"
        ></v-button>
        <v-button size="medium" @click="submit" text="确定"></v-button>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getBuildListURL,
  activityDetail,
  activityEdit,
  activityPointSettingList,
  areaListUrl,
} from "./api.js";
import {
  activityTypeList,
  sceneIdsListMap,
  sceneIdsListOps,
  sceneIdsList,
  reportScenes,
  reportScenesMap,
  reportScenesOps,
  setIsTopOps,
  setUserTypeOps,
  isHidden,
  isHiddenMap,
} from "./map.js";
export default {
  name: "addActivity",
  data() {
    return {
      isHidden,
      // 活动报名方式
      registrationMethodObj: [
        { value: 1, label: "短期模式" },
        { value: 2, label: "周期模式" },
      ],
      isTopOps: setIsTopOps(),
      footerShow: true,
      reportScenesList: reportScenesOps(),
      activityTypeList,
      // 活动场景类型
      activitySceneTypeRadio: [
        { name: "1", value: "党建" },
        { name: "2", value: "公益" },
        { name: "3", value: "社群" },
        { name: "4", value: "商业" },
        { name: "5", value: "其他" },
      ],
      // 是否限制单次报名参与人数
      radioObj: [
        { name: "2", value: "不限制" },
        { name: "1", value: "限制" },
      ],
      // 是否允许报名多场
      radioApplyObj: [
        { name: "2", value: "否" },
        { name: "1", value: "是" },
      ],
      activityPointSettingList: [],
      submitConfig: {
        queryUrl: activityDetail,
        submitUrl: activityEdit,
      },
      areaList: [],
      oIndex: null,
      isEdit: false,
      showDialog3: false,
      showDialog2: false,
      // 定期模式(时间银行)表头
      headers3: [
        {
          prop: "startTime",
          label: "开始时间",
        },
        {
          prop: "endTime",
          label: "结束时间",
        },
        {
          prop: "limitCount",
          label: "需要人数",
        },
      ],
      // 周期模式表头
      headers2: [
        {
          prop: "week",
          label: "星期",
          formatter: (row, prop) => {
            if (row.week == "1") {
              return "周一";
            } else if (row.week == "2") {
              return "周二";
            } else if (row.week == "3") {
              return "周三";
            } else if (row.week == "4") {
              return "周四";
            } else if (row.week == "5") {
              return "周五";
            } else if (row.week == "6") {
              return "周六";
            } else if (row.week == "7") {
              return "周日";
            }
          },
        },
        {
          prop: "startTime",
          label: "开始时间",
        },
        {
          prop: "endTime",
          label: "结束时间",
        },
      ],
      shortTermSessionsList: [], //短期
      periodSessionsList: [], //周期
      fixDateSessionsList: [], //定期
      // 定期模式item数据
      item3: {
        startTime: null, //开始时间
        endTime: null, //结束时间
        dayTimeBucket: null, //时间段
        pointsSettingId: null, //积分规则id
        limitCount: 0, //活动人数
        perLimitCount: 0, //单次报名参与人数默认传0
        isLimit: "2", //是否限制单次报名参与人数
      },
      // 周期模式item数据
      item2: {
        week: null, //星期
        startTime: null, //开始时间
        endTime: null, //结束时间
        dayTimeBucket: null, //时间段
        pointsSettingId: null, //积分规则id
        limitCount: 0, //活动人数默认传0
        perLimitCount: 0, //单次报名参与人数默认传0
        isLimit: "2", //是否限制单次报名参与人数
      },
      dialogWeekList: [], // 弹框内星期列表
      // 星期列表
      weekList: [
        {
          value: 1,
          checked: false,
          label: "周一",
        },
        {
          value: 2,
          checked: false,
          label: "周二",
        },
        {
          value: 3,
          checked: false,
          label: "周三",
        },
        {
          value: 4,
          checked: false,
          label: "周四",
        },
        {
          value: 5,
          checked: false,
          label: "周五",
        },
        {
          value: 6,
          checked: false,
          label: "周六",
        },
        {
          value: 7,
          checked: false,
          label: "周日",
        },
      ],
      pointsSettingId: null, // 积分规则
      applyDate23: [], //报名时间
      activityDate23: [], //活动时间
      form: {
        weeks: null, //星期数据集合
        activityPattern: null, //活动报名方式
        activityCategory: null, //活动类型
        isRecommend: null,
        hiddenFlag: null,
        sortIndex: "",
        activityStatus: "",
        sceneIds: "",
        statisticsScenes: "",
        activitySceneType: "1",
        activityPicture: "", //活动图片
        commitmentLetter: "",
        allowApplyMulti: "1", //是否允许报名多场
        communityId: null,
        activityId: null,
        activityName: "",
        activityAddress: "",
        placeId: "",
        limitCount: null,
        scheduleList: [],
        applyStartDate: "", //报名开始时间
        applyEndDate: "", //报名结束时间

        startDate: "", //活动开始时间
        endDate: "", //活动结束时间
        content: "", //活动文本
        subImg: "",

        imageUrls: "", //活动图片
        videoUrl: "", //活动视频

        authSpaceIds: "", // 区域id集合
        authSpaceTypes: undefined, // 用户类别id
      },
      sceneIdsList: sceneIdsListOps(),
      // -----------------------
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
      userTypeOps: setUserTypeOps(),

      isEditMode: false, // 是否编辑页面 编辑 or 新增
    };
  },
  async mounted() {
    this.getAreaList();
    // 10 居民 11 商户
    await Promise.all([
      this.getBuildList(10),
      this.getBuildList(11),
      this.getBuildList("10,11"),
    ]);
    const { activityId } = this.$route.query;
    if (activityId !== undefined) {
      this.$refs.formPanel.getData({ activityId });
    } else {
      this.form.placeId = ""; // 由 null 修改为 ''，目前未找到 必须使用 null 的原因，null 导致代码报错
    }
    await this.getActivityPointSettingList();
    this.isEditMode = !!activityId;
    this.$setBreadList(activityId ? "编辑" : "新增");
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中类别集合
    sceneIdsList: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.sceneIds =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    reportScenesList: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.statisticsScenes =
          checkOps.length > 0 ? checkOps.map((v) => v.value).join(",") : "";
      },
      deep: true,
    },
    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes: {
      handler() {
        let types =
          this.form.authSpaceTypes !== undefined
            ? this.form.authSpaceTypes.split(",")
            : [];
        let result = [];
        if (types.includes("11") && types.includes("10")) {
          result = [...this.allOps];
        } else if (types.includes("11")) {
          result.push(...this.merchantsOps);
        } else if (types.includes("10")) {
          result.push(...this.residentsOps);
        }
        this.sectionOps = result;
      },
      immediate: true,
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  methods: {
    validatorWeek(rule, value, callback) {
      let weekList = this.weekList;
      let flag = false;
      weekList.forEach((ele) => {
        if (ele.checked) {
          flag = true;
        }
      });
      if (!flag) {
        callback(new Error("请选择星期"));
      } else {
        callback();
      }
    },
    // 活动报名方式切换
    activityPatternChange(type) {
      if (type == 1) {
        this.form.scheduleList = [];
        this.form.scheduleList.push({
          limitCount: "", //活动人数
          isLimit: "2", //是否限制单次报名参与人数
          perLimitCount: 0, //单次报名参与人数
          applyDate: [], //报名时间
          activityDate: [], //活动时间
          pointsSettingId: null, //代币规则id
        });
      } else {
        this.form.scheduleList = [];
      }
    },
    // 活动类型切换
    activityCategoryChange(type) {},
    // 编辑
    editSession(row, index) {
      this.isEdit = true;
      this.oIndex = index;
      console.log(row);
      if (this.form.activityCategory == 20) {
        Object.keys(this.item3).forEach((key) => {
          this.item3[key] = row[key];
        });
        this.showDialog3 = true;
      } else {
        let flag = false;
        this.weekList.forEach((ele) => {
          if (ele.checked) {
            flag = true;
          }
        });
        if (!flag) {
          this.$message("请选择星期");
          return;
        }
        this.dialogWeekList = [];
        this.weekList.forEach((ele) => {
          if (ele.checked) {
            this.dialogWeekList.push({ value: ele.value, label: ele.label });
          }
        });
        Object.keys(this.item2).forEach((key) => {
          this.item2[key] = row[key];
        });
        this.showDialog2 = true;
      }
    },
    // 删除
    delSession(row, index) {
      if (this.form.activityCategory == 20) {
        this.form.scheduleList.splice(index, 1);
      } else {
        this.form.scheduleList.splice(index, 1);
      }
    },
    // 添加场次
    addSession() {
      this.isEdit = false;
      this.oIndex = "";
      if (this.form.activityCategory == 20) {
        //时间银行-定期模式
        this.showDialog3 = true;
        this.item3 = {
          startTime: null, //开始时间
          endTime: null, //结束时间
          dayTimeBucket: null, //时间段
          pointsSettingId: null, //积分规则id
          limitCount: 0, //活动人数
          perLimitCount: 0, //单次报名参与人数默认传0
          isLimit: "2", //是否限制单次报名参与人数
        };
      } else {
        // 周期模式
        let flag = false;
        this.weekList.forEach((ele) => {
          if (ele.checked) {
            flag = true;
          }
        });
        if (!flag) {
          this.$message("请选择星期");
          return;
        }
        this.dialogWeekList = [];
        this.weekList.forEach((ele) => {
          if (ele.checked) {
            this.dialogWeekList.push({ value: ele.value, label: ele.label });
          }
        });
        this.showDialog2 = true;
        this.item2 = {
          week: null, //星期
          startTime: null, //开始时间
          endTime: null, //结束时间
          dayTimeBucket: null, //时间段
          pointsSettingId: null, //积分规则id
          limitCount: 0, //活动人数默认传0
          perLimitCount: 0, //单次报名参与人数默认传0
          isLimit: "2", //是否限制单次报名参与人数默认传2
        };
      }
    },
    timeFormat(str) {
      let list = str.split(":");
      let num = 60 * Number(list[0]) + Number(list[1]);
      return num;
    },
    changeWeekList(item) {
      console.log(item, "item---");
      if (!item.checked) {
        this.$set(
          this.form,
          "scheduleList",
          this.form.scheduleList.filter((v) => v.week != item.value)
        );
      }
    },
    // 添加场次-确定
    submit() {
      if (this.form.activityCategory == 20) {
        if (!this.item3.startTime) {
          this.$message("请选择开始时间");
          return;
        }
        if (!this.item3.endTime) {
          this.$message("请选择结束时间");
          return;
        }
        if (
          this.timeFormat(this.item3.startTime) >
          this.timeFormat(this.item3.endTime)
        ) {
          this.$message("开始时间不能大于结束时间");
          return;
        }
        // 时间银行-定期模式
        if (this.isEdit) {
          this.$set(this.form.scheduleList, this.oIndex, this.item3);
        } else {
          this.form.scheduleList.push(this.item3);
        }
        this.item3 = {
          startTime: null, //开始时间
          endTime: null, //结束时间
          dayTimeBucket: null, //时间段
          pointsSettingId: null, //积分规则id
          limitCount: 0, //活动人数
          perLimitCount: 0, //单次报名参与人数默认传0
          isLimit: "2", //是否限制单次报名参与人数
        };
        this.showDialog3 = false;
      } else {
        // 周期模式
        if (!this.item2.week) {
          this.$message("请选择星期");
          return;
        }
        if (!this.item2.startTime) {
          this.$message("请选择开始时间");
          return;
        }
        if (!this.item2.endTime) {
          this.$message("请选择结束时间");
          return;
        }
        if (
          this.timeFormat(this.item2.startTime) >
          this.timeFormat(this.item2.endTime)
        ) {
          this.$message("开始时间不能大于结束时间");
          return;
        }
        if (this.isEdit) {
          this.$set(this.form.scheduleList, this.oIndex, this.item2);
        } else {
          this.form.scheduleList.push(this.item2);
        }
        this.item2 = {
          week: null, //星期
          startTime: null, //开始时间
          endTime: null, //结束时间
          dayTimeBucket: null, //时间段
          pointsSettingId: null, //积分规则id
          limitCount: 0, //周期模式活动人数默认传0
          perLimitCount: 0, //周期模式单次报名参与人数默认传0
          isLimit: "2", //周期模式是否限制单词
        };
        this.showDialog2 = false;
      }
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        // 10 用户 11 商圈
        if (type === 10) {
          this.residentsOps = opt;
        } else if (type === 11) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
        // this.sectionOps = opt;
      }
    },
    selectPlace() {
      this.areaList.forEach((ele) => {
        if (ele.placeId == this.form.placeId) {
          this.form.activityAddress = ele.placeName;
        } else if (this.form.placeId.length == 0) {
          this.form.activityAddress = "";
        }
      });
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
    spaceTypeValid(rule, value, callback) {
      if (value === undefined) {
        callback(new Error("请选择用户类别"));
      } else {
        callback();
      }
    },
    changeActivitySceneType() {},
    pickerOptions() {},
    outRoom(item, index) {
      this.form.scheduleList.splice(index, 1);
    },
    addRoom() {
      this.form.scheduleList.push({
        limitCount: "",
        perLimitCount: 0,
        applyDate: [],
        activityDate: [],
        pointsSettingId: null,
        isLimit: "2",
      });
    },
    changeApply(value) {
      console.log(value);
      this.form.allowApplyMulti = String(value);
    },
    change(value, item) {
      console.log(value, item);
      item.isLimit = String(value);
      this.$forceUpdate();
    },
    async getActivityPointSettingList() {
      this.activityPointSettingList = [];
      let res = await this.$axios.get(`${activityPointSettingList}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            this.activityPointSettingList.push({
              value: item.settingId.toString(),
              label: item.settingName,
            });
          });
        }
      } else {
        if (res.msg) {
          this.$message.error(res.msg);
        }
      }
    },
    getAreaList() {
      this.areaList = [];
      this.$axios.get(`${areaListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.placeName;
            item.value = item.placeId;
            this.areaList.push(item);
          });
          let obj = {
            label: "其他",
            value: "",
          };
          this.areaList.push(obj);
        } else {
        }
      });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      // 发布范围start
      this.authSpaceIds = [];
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      let types = this.form.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        }
      });
      // 发布范围end
      if (this.form.scheduleList && this.form.activityPattern == 1) {
        this.form.scheduleList.forEach((ele) => {
          ele.pointsSettingId = ele.pointsSettingId
            ? String(ele.pointsSettingId)
            : "";
          if (ele.perLimitCount && Number(ele.perLimitCount) > 0) {
            ele.isLimit = "1";
          } else {
            ele.isLimit = "2";
          }
          ele.activityDate = [];
          ele.activityDate.push(new Date(ele.startDate));
          ele.activityDate.push(new Date(ele.endDate));
          ele.applyDate = [];
          ele.applyDate.push(new Date(ele.applyStartDate));
          ele.applyDate.push(new Date(ele.applyEndDate));
        });
      } else {
        this.applyDate23 = [
          new Date(this.form.applyStartDate),
          new Date(this.form.applyEndDate),
        ];
        this.activityDate23 = [
          new Date(this.form.startDate),
          new Date(this.form.endDate),
        ];
        this.form.scheduleList.forEach((ele) => {
          ele.startTime = ele.dayTimeBucket.split("~")[0];
          ele.endTime = ele.dayTimeBucket.split("~")[1];
        });
      }
      // 选择星期
      if (this.form.weeks) {
        this.form.weeks.split(",").forEach((ele) => {
          this.weekList.forEach((ele2) => {
            if (Number(ele2.value) == Number(ele)) {
              ele2.checked = true;
            }
          });
        });
      }
      // 积分规则
      if (this.form.activityPattern && this.form.activityPattern != 1) {
        this.pointsSettingId =
          this.form.scheduleList[0].pointsSettingId.toString();
      } else {
        this.pointsSettingId = null;
      }
      // 是否允许报名多场
      if (this.form.allowApplyMulti) {
        this.form.allowApplyMulti = String(this.form.allowApplyMulti);
      } else {
        this.form.allowApplyMulti = "1";
      }
      // 活动场景类型
      if (this.form.activitySceneType) {
        this.form.activitySceneType = String(this.form.activitySceneType);
      } else {
        this.form.activitySceneType = "1";
      }
      // 场景id
      if (this.form.sceneIds) {
        let types = this.form.sceneIds.split(",");
        this.sceneIdsList.forEach((v) => {
          if (types.includes(v.value)) {
            v.checked = true;
          }
        });
      }
      // 上报用场景类型
      if (this.form.statisticsScenes) {
        let scenesTypes = this.form.statisticsScenes.split(",");
        this.reportScenesList.forEach((v) => {
          if (scenesTypes.includes(v.value)) {
            v.checked = true;
          }
        });
      }
      if (this.form.activityStatus == 6) {
        this.footerShow = false;
      }
    },
    submitBefore() {
      if (this.form.placeId == "" && this.form.activityAddress == "") {
        this.$message("请输入活动地点");
        return;
      }
      // 报名方式为周期模式||活动类型为时间银行
      if (this.form.activityPattern == 2 || this.form.activityCategory == 20) {
        // 时间银行没有选择星期
        if (this.form.activityPattern == 2) {
          let weekflag = false;
          this.weekList.forEach((ele) => {
            if (ele.checked) {
              weekflag = true;
            }
          });
          if (!weekflag) {
            this.$message("请选择星期");
            return;
          }
          let weekCheckedList = [];
          let scheduleWeekList = [];
          let setScheduleWeekList = [];
          let submitFlag = false;
          this.weekList.forEach((ele) => {
            if (ele.checked) {
              weekCheckedList.push(ele.value);
            }
          });
          this.form.scheduleList.forEach((ele) => {
            scheduleWeekList.push(ele.week);
          });
          setScheduleWeekList = [...new Set(scheduleWeekList)];
          if (weekCheckedList.length == setScheduleWeekList.length) {
            submitFlag = true;
          }
          if (!submitFlag) {
            this.$message("所选星期最少配置一个场次!");
            return;
          }
          // 选择星期赋值
          let weeks = [];
          this.weekList.forEach((ele) => {
            if (ele.checked) {
              weeks.push(ele.value);
            }
          });
          this.form.weeks = weeks.join(",");
        }
        // 活动类型为时间银行的情况下活动报名方式字段默认传3
        if (this.form.activityCategory == 20) {
          this.form.activityPattern = 3;
        }
        if (this.applyDate23.length == 0 || this.activityDate23.length == 0) {
          this.$message("报名时间/活动时间不能为空");
          return;
        }
        if (!this.pointsSettingId) {
          this.$message("请选择积分规则");
          return;
        }
        this.form.applyStartDate = //报名开始时间
          this.applyDate23.length > 0
            ? this.applyDate23[0].format("yyyy-MM-dd HH:mm")
            : "";
        this.form.applyEndDate = //报名结束时间
          this.applyDate23.length > 0
            ? this.applyDate23[1].format("yyyy-MM-dd HH:mm")
            : "";
        this.form.startDate = //活动开始时间
          this.activityDate23.length > 0
            ? this.activityDate23[0].format("yyyy-MM-dd HH:mm")
            : "";
        this.form.endDate = //活动结束时间
          this.activityDate23.length > 0
            ? this.activityDate23[1].format("yyyy-MM-dd HH:mm")
            : "";
        // 场次内积分规则/时间段赋值
        this.form.scheduleList.forEach((ele) => {
          ele.pointsSettingId = this.pointsSettingId;
          ele.dayTimeBucket = `${ele.startTime}~${ele.endTime}`;
        });
      }
      // 活动报名方式为短期时处理场次 报名时间 活动时间
      if (this.form.activityPattern == 1) {
        this.form.scheduleList.forEach((ele) => {
          if (!ele.activityDate) {
            ele.startDate = "";
            ele.endDate = "";
          }
          if (!ele.applyDate) {
            ele.applyStartDate = "";
            ele.applyEndDate = "";
          }
          ele.applyStartDate =
            ele.applyDate.length > 0
              ? ele.applyDate[0].format("yyyy-MM-dd HH:mm")
              : "";
          ele.applyEndDate =
            ele.applyDate.length > 0
              ? ele.applyDate[1].format("yyyy-MM-dd HH:mm")
              : "";
          ele.startDate =
            ele.activityDate.length > 0
              ? ele.activityDate[0].format("yyyy-MM-dd HH:mm")
              : "";
          ele.endDate =
            ele.activityDate.length > 0
              ? ele.activityDate[1].format("yyyy-MM-dd HH:mm")
              : "";
        });
      }
      if (this.form.scheduleList.length == 0) {
        this.$message("请选择场次");
        return;
      }
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
  /deep/ .el-form-item__label {
    width: 160px !important;
  }
  .inputPeople {
    display: flex;
  }
  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 46px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
